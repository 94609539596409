import React from "react"
import { Link } from "gatsby"

import headerImage from '../images/ecommerce/magento-demos@2x.png';
import logoMagento from '../images/ecommerce/magento_logo.svg';

import './css/ecommerce.scss'
import SEO from "../components/seo"
import Layout from "../components/layout";



const Ecommerce = () => (
  <Layout>
    <SEO title="Ecommerce" description="Sua Loja Virtual na Melhor Plataforma do Mercado." keywords={[`ecommerce`, `loja virtual`, `magento`]} />
    <div className="main" role="main">
      <div className="main-img ui-hero hero-lg hero-center hero-svg-layer-2  ui-gradient-sc bg-magento ui-tilt hero-svg-layer svg-layer-2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12" data-vertical_center="true" data-vertical_offset="16">
              <h1 className="heading animate" data-show="fade-in-up-big" data-delay="100">
                Sua <span className="text-orange-dark">Loja Virtual</span> na Melhor <span className="text-orange-dark">Plataforma</span> do Mercado
              </h1>
            </div>
            <div className="col-sm-12 animate" data-show="fade-in-up" data-delay="400">
              <img src={headerImage} alt="Magento 2 System Code"
                   data-uhd data-max_width="1000" className="responsive-on-lg" />
            </div>
          </div>
        </div>
      </div>
      <div id="features" className="section">
        <div className="container ui-icon-blocks ui-blocks-h icons-lg">
          <div className="row">
            <div className="col-sm-4 ui-icon-block">
              <div className="icon icon-lg icon-circle icon-rocket ui-gradient-blue"></div>
              <h4>Desenvolvimento</h4>
              <p>
                Criamos sua loja do zero adaptando à sua necessidade!
              </p>
            </div>
            <div className="col-sm-4 ui-icon-block">
              <div className="icon icon-lg icon-circle icon-earphones-alt ui-gradient-peach"></div>
              <h4>Suporte / Manutenção</h4>
              <p>
                Equipe de suporte especializada 24/7<br/>para dúvidas ou problemas técnicos.
              </p>
            </div>
            <div className="col-sm-4 ui-icon-block">
              <div className="icon icon-lg icon-circle icon-trophy ui-gradient-green"></div>
              <h4>Consultoria</h4>
              <p>
                Consultoria especializada para alavancar as vendas de sua loja virtual.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section bg-magento-dark ui-action-section ui-hero hero-svg-layer-3">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-7 text-block" data-vertical_center="true">
              <div className="section-heading">
                <h2 className="heading">
                  Já conhece o Magento?
                </h2>
                <p>
                  O <strong>Magento</strong> é uma das plataformas de e-commerce mais utilizadas no mundo e em sua versao 2.x foi bastante reformulado e hoje fornece o que há de melhor em tecnologia.
                </p>
                <p>Recentemente o Magento foi adquirido pela Adobe, uma gigante da tecnologia, por terem visto todo o potencial que o Magento oferece.</p>
                <p>
                  Por ser uma plataforma robusta, o desenvolvimento requer em sua maioria uma equipe especializada para aproveitar ao máximo a plataforma e nós da <strong>System Code</strong> sabemos te ajudar!
                </p>
              </div>
            </div>
            <div className="logo-magento col-md-4 col-sm-5 img-block animate" data-show="fade-in-left">
              <img src={logoMagento} alt="Magento 2"
                   data-uhd className="responsive-on-sm" data-max_width="450"/>
            </div>
          </div>
        </div>
      </div>


      <div className="section bg-light">
        <div className="container ui-icon-blocks ui-blocks-h icons-md">
          <div className="section-heading center">
            <h2 className="heading text-indigo">
              O Que Oferecemos
            </h2>
            <p>
              Conheça os principais recursos que oferecemos para nossos clientes na plaforma <strong>Magento 2</strong>
            </p>
          </div>
          <div className="row animate" data-show="fade-in" >
            <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
              <div className="icon icon-wrench text-purple"></div>
              <h5>Módulos</h5>
              <p>
                Desenvolvimento e/ou instalação de módulos customizados.
              </p>
            </div>
            <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
              <div className="icon icon-puzzle text-lime"></div>
              <h5>Temas Personalizados</h5>
              <p>
                Criação e customização de temas com a identidade do seu negócio.
              </p>
            </div>
            <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
              <div className="icon icon-settings text-pink"></div>
              <h5>Integrações</h5>
              <p>
                Realizações de integrações com ERP's e Marketplaces.
              </p>
            </div>
            <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
              <div className="icon icon-speedometer text-blue"></div>
              <h5>Infraestrutura</h5>
              <p>
                Escalável, rápida e eficiente para fornecer melhor experência para o seu cliente.
              </p>
            </div>
            <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
              <div className="icon icon-lock text-orange"></div>
              <h5>Segurança</h5>
              <p>
                Mantemos sua loja segura com atualizações e HTTPS.
              </p>
            </div>
            <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
              <div className="icon icon-people text-dark"></div>
              <h5>Treinamento</h5>
              <p>
                Treinamos sua equipe para aproveitar todos os recursos da plataforma.
              </p>
            </div>
            <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
              <div className="icon icon-layers text-cyan"></div>
              <h5>SEO/SEM</h5>
              <p>
                Sua loja otimizada em plataformas de pesquisa e em links patrocinados.
              </p>
            </div>
            <div className="col-md-3 col-sm-4 col-6 ui-icon-block">
              <div className="icon icon-tag text-yellow"></div>
              <h5>Ferramentas</h5>
              <p>
                Amplie suas vendas em outras plataformas como o Google Shopping e Facebook.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12" data-vertical_center="true">
              <div className="section-heading">
                <h2 className="heading text-indigo text-center">
                  Saiba mais
                </h2>

                <p className="paragraph faq">
                  Confirma algumas dúvidas mais comuns na hora de ter sua loja virtual
                </p>

                <div className="ui-accordion-panel ui-indigo">
                  <div className="ui-card shadow-sm ui-accordion">
                    <h6 className="toggle" data-toggle="accordion-one">1. Para quem recomendam?</h6>
                    <div className="body in" data-accord="accordion-one">
                      <p>Nós da <strong>System Code</strong> recomendamos o uso de lojas virtuais em <strong>Magento 2</strong> para empresas de
                        médio a grande porte. O <strong>Magento 2</strong> é uma plataforma robusta e altamente
                        recomendada para atender à altas demandas e catálogos de produtos variados de forma segura e atrativa para os
                        clientes.
                      </p>
                    </div>
                  </div>

                  <div className="ui-card shadow-sm ui-accordion">
                    <h6 className="toggle" data-toggle="accordion-two">2. Por que ter uma loja virtual?</h6>
                    <div className="body" data-accord="accordion-two">
                      <p>Ter uma loja virtual é como abrir uma nova filial de sua empresa e com ela conseguir ampliar suas vendas
                        para todo território nacional ou até mesmo vendas para o exterior.
                        Além disso você terá seu catálogo de produtos/serviços disponíveis para vendas 24h por dia.
                      </p>
                    </div>
                  </div>

                  <div className="ui-card shadow-sm ui-accordion">
                    <h6 className="toggle" data-toggle="accordion-three">3. Por onde começar?</h6>
                    <div className="body" data-accord="accordion-three">
                      <p>O primeiro passo é definir qual será o produto/serviço vendido na sua loja além de realizar
                        pesquisas sobre os principais concorrentes para conhecer o mercado escolhido.
                        Com isso em mãos, entre em contato com conosco, possuimos uma
                        equipe qualificada para auxiliar no processo de criação de sua loja virtual.
                      </p>
                    </div>
                  </div>

                  <div className="ui-card shadow-sm ui-accordion">
                    <h6 className="toggle" data-toggle="accordion-four">4. Já tenho uma loja!</h6>
                    <div className="body" data-accord="accordion-four">
                      <p>Caso pretenda utilizar o <strong>Magento 2</strong> ou esteja precisando de uma empresa capacitada para
                        auxiliar no processo ou melhorias de sua loja virtual, conte conosco.
                      </p>
                    </div>
                  </div>

                  <div className="ui-card shadow-sm ui-accordion">
                    <h6 className="toggle" data-toggle="accordion-five">5. Ainda com dúvidas?</h6>
                    <div className="body" data-accord="accordion-five">
                      <p>Ficou interessado em ter sua loja virtual em <strong>Magento 2</strong> mas continua com alguma dúvida? Entre em contato
                        com a <strong>System Code</strong> que iremos te ajudar!
                      </p>
                      <Link to="contato" className="btn-link btn-arrow">Entre em Contato</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section bg-indigo">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <div className="section-heading">
                <h2 className="heading">Iniciativa Open Source</h2>
                <p>
                  A <strong>System Code</strong> com o intuito de divulgar a plataforma <strong>Magento 2</strong> no Brasil e ajudar na implementação do Magento para o seu negócio,
                  oferece gratuitamente um de nossos módulos em <strong>Magento 2</strong> no qual adapta os campos de usuário e endereço para o padrão brasileiro.
                </p>
              </div>
            </div>
            <div className="col-md-2" data-vertical_center="true">
              <div className="actions text-right">
                <a className="btn btn-github btn-download shadow-lg" href="https://github.com/eduardoddias/Magento-SystemCode_BrazilCustomerAttributes/" target="_blank">
                  <span>Ver no</span>
                  <span>Github</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Layout>
)

export default Ecommerce
